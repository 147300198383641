.paddingnav {
    padding: 0.2rem calc((100vw - 1000px) / 2);
    z-index: 12;
    background-color: white;
    color: 051367;
}

.inset-center {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

.logo {
    max-width:100%;
    max-height:100%;
}

.stick-ontop {
    position: -webkit-sticky; 
    position: sticky; 
    top: 0;
    align-self: flex-start;
    height: auto;
  }